var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showInformations,
              expression: "showInformations",
            },
          ],
          staticClass: "wrapper",
        },
        [
          _c(
            "div",
            { staticClass: "wrapper__card" },
            [
              _c("div", { staticClass: "wrapper__card__header" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _vm.isEditing
                      ? _c("z-btn", {
                          attrs: {
                            text: "Jornada rápida",
                            primary: "",
                            rounded: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.confirmationFastJourney = true
                            },
                          },
                        })
                      : _vm._e(),
                    _c("z-btn", {
                      attrs: {
                        text: "Mapeamento de variáveis",
                        primary: "",
                        rounded: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openVariablesModalFunc()
                        },
                      },
                    }),
                    _c("z-btn", {
                      attrs: { text: "Utm Tags", primary: "", rounded: "" },
                      on: {
                        click: function ($event) {
                          return _vm.openUtmsModalFunc()
                        },
                      },
                    }),
                    _c("z-btn", {
                      attrs: {
                        text: "Envio teste",
                        primary: "",
                        rounded: "",
                        icon: "mdi-send",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openTestModalFunc()
                        },
                      },
                    }),
                    _c("z-btn", {
                      attrs: {
                        text: "Salvar Template",
                        rounded: "",
                        primary: "",
                        "is-loading": _vm.loadingTempalte,
                        color: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.saveTemplate()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "wrapper__card__body mb-5" }, [
                _c(
                  "div",
                  { staticClass: "wrapper__card__body__left" },
                  [
                    _c(
                      "v-form",
                      { ref: "form" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "5", sm: "5" } },
                              [
                                _c("z-input", {
                                  attrs: {
                                    rules: [(v) => !!v || "Campo obrigatório"],
                                    hideDetails: true,
                                    placeholder: "Nome do template",
                                    type: "text",
                                  },
                                  model: {
                                    value: _vm.templateToSave.templateName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.templateToSave,
                                        "templateName",
                                        $$v
                                      )
                                    },
                                    expression: "templateToSave.templateName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "5", sm: "5" } },
                              [
                                _c("z-checkbox", {
                                  staticStyle: {
                                    "padding-top": "5px",
                                    "margin-left": "10px",
                                  },
                                  attrs: { label: "Permitir Optout" },
                                  model: {
                                    value: _vm.templateToSave.allowOptout,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.templateToSave,
                                        "allowOptout",
                                        $$v
                                      )
                                    },
                                    expression: "templateToSave.allowOptout",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "5", sm: "5" } },
                              [
                                _c("z-autocomplete", {
                                  attrs: {
                                    label: "Selecione uma conexão",
                                    items: _vm.dbConfigList,
                                    itemText: "name",
                                    placeholder: "Pesquisar",
                                    type: "text",
                                    hideDetails: true,
                                    returnObject: "",
                                    rules: [(v) => !!v || "Campo obrigatório"],
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.chooseCollection()
                                    },
                                  },
                                  model: {
                                    value: _vm.choosenConnection,
                                    callback: function ($$v) {
                                      _vm.choosenConnection = $$v
                                    },
                                    expression: "choosenConnection",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticStyle: { "margin-bottom": "15px" } },
                          [
                            _c("label", [
                              _vm._v(
                                "Para utilizar variáveis em seu template utilize da seguinte forma: $id"
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "10", sm: "12" } },
                              [
                                _c(
                                  "Mentionable",
                                  {
                                    attrs: {
                                      keys: ["$"],
                                      items: _vm.variables,
                                      offset: "0",
                                      limit: 100,
                                      "insert-space": "",
                                      placement: "bottom",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "no-result",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "popoveruser",
                                                staticStyle: {
                                                  "text-align": "center",
                                                  padding: "15px",
                                                  "margin-right": "10px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.choosenConnection
                                                        ? "Sem Resultados"
                                                        : "Selecione uma conexão!"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "item-$",
                                        fn: function ({ item }) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "popoveruser" },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.value) + " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  },
                                  [
                                    _c("z-input-area", {
                                      attrs: {
                                        max: "",
                                        rules: [
                                          (v) => !!v || "Campo obrigatório",
                                        ],
                                        hideDetails: true,
                                        placeholder: "Conteúdo",
                                        type: "textarea",
                                      },
                                      model: {
                                        value: _vm.templateToSave.message,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.templateToSave,
                                            "message",
                                            $$v
                                          )
                                        },
                                        expression: "templateToSave.message",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-row", { attrs: { justify: "end" } }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.charactersCount) +
                                "/" +
                                _vm._s(_vm.totalMessageLength) +
                                " (" +
                                _vm._s(_vm.totalSMSs) +
                                ")"
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "wrapper__card__body__right",
                    staticStyle: { height: "100% !important" },
                  },
                  [
                    _c("div", { staticClass: "iphone-x" }, [
                      _c("div", { staticClass: "side" }, [
                        _c("div", { staticClass: "screen" }, [
                          _c("div", { staticClass: "content" }, [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "box-message",
                                  staticStyle: {
                                    "letter-spacing": "0.15px !important",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.transformString(
                                          _vm.templateToSave.message
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "text-input-iphone" },
                            [
                              _c(
                                "v-row",
                                {
                                  staticStyle: {
                                    "justify-content": "space-evenly",
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v(" mdi-plus ")]),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "0.85rem" } },
                                    [_vm._v("Digite uma mensagem SMS")]
                                  ),
                                  _c("v-icon", [_vm._v(" mdi-send")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "line" }),
                      _vm._m(1),
                      _c("div", { staticClass: "volume-button" }),
                      _c("div", { staticClass: "power-button" }),
                    ]),
                  ]
                ),
              ]),
              _c("v-row", { attrs: { justify: "end" } }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showInformations,
              expression: "!showInformations",
            },
          ],
          staticClass: "wrapper no-data",
        },
        [_c("div", { staticClass: "wrapper__card" }, [_c("z-loading")], 1)]
      ),
      _c("z-card-modal", {
        attrs: {
          width: "600",
          title:
            "tem certeza que deixar criar uma nova jornada com esse template?",
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "fast-journey-modal" }, [
                  _c("div", { staticClass: "pt-3 pb-5" }, [
                    _c("span", [
                      _vm._v(" Template selecionado: "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.templateToSave.templateName)),
                      ]),
                    ]),
                    _c("br"),
                    _c("span", [
                      _vm._v(
                        " Selecione uma audiência abaixo para dar proseguimento "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "v-form",
                        { ref: "confirmFastForm" },
                        [
                          _c("z-autocomplete", {
                            attrs: {
                              label: "Audiência",
                              items: _vm.audiencesList,
                              itemText: "name",
                              placeholder: "Pesquisar",
                              type: "text",
                              hideDetails: true,
                              returnObject: "",
                              rules: [(v) => !!v || "Campo obrigatório"],
                            },
                            model: {
                              value: _vm.choosenAudience,
                              callback: function ($$v) {
                                _vm.choosenAudience = $$v
                              },
                              expression: "choosenAudience",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "pt-5 pb-1 d-flex justify-end" },
                    [
                      _c("z-btn", {
                        staticClass: "mr-2",
                        attrs: { text: "Cancelar", rounded: "" },
                        on: {
                          click: function ($event) {
                            _vm.confirmationFastJourney = false
                            _vm.choosenAudience = null
                          },
                        },
                      }),
                      _c("z-btn", {
                        attrs: {
                          text: "Confirmar",
                          primary: "",
                          rounded: "",
                          disabled: !_vm.choosenAudience,
                        },
                        on: { click: _vm.createSimpleJourney },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.confirmationFastJourney,
          callback: function ($$v) {
            _vm.confirmationFastJourney = $$v
          },
          expression: "confirmationFastJourney",
        },
      }),
      _c("z-card-modal", {
        attrs: { width: "400", title: "Tags disponíveis para uso" },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass: "cards-audience",
                    staticStyle: {
                      gap: "1rem",
                      padding: "1rem 0",
                      "flex-wrap": "wrap",
                      color: "333333",
                    },
                  },
                  _vm._l(_vm.tags, function (item, index) {
                    return _c("div", { key: index }, [
                      _c("span", [
                        _vm._v(" Tag para " + _vm._s(item.text) + ": "),
                        _c("strong", [_vm._v(_vm._s(item.value))]),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.showTagsModal,
          callback: function ($$v) {
            _vm.showTagsModal = $$v
          },
          expression: "showTagsModal",
        },
      }),
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.openTestModal,
            callback: function ($$v) {
              _vm.openTestModal = $$v
            },
            expression: "openTestModal",
          },
        },
        [
          _c("TestModal", {
            attrs: {
              "choosen-connection-prop": _vm.choosenConnection,
              "template-to-save-prop": _vm.objToSendTest,
              firstData: _vm.firstData,
              defaultValues: _vm.defaultValues,
              databaseFields: _vm.databaseFields,
              messageContent: _vm.templateToSave.message,
            },
            on: {
              close: function ($event) {
                ;(_vm.openTestModal = false), (_vm.objToSendTest = null)
              },
              onErroInInformations: function ($event) {
                return _vm.$refs.form.validate()
              },
            },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "800" },
          model: {
            value: _vm.openVariablesModal,
            callback: function ($$v) {
              _vm.openVariablesModal = $$v
            },
            expression: "openVariablesModal",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal",
              staticStyle: { "z-index": "9999 !important" },
            },
            [
              _c("div", { staticClass: "modal__header" }, [
                _c("div", { staticClass: "modal__header__title" }, [
                  _c("span", [_vm._v("Variaveis ")]),
                  _c("br"),
                ]),
                _c(
                  "div",
                  { staticClass: "action" },
                  [
                    _c(
                      "v-icon",
                      {
                        on: {
                          click: function ($event) {
                            _vm.openVariablesModal = false
                          },
                        },
                      },
                      [_vm._v("mdi-close")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "modal__body" }, [
                _c("div", { staticClass: "variableTableDiv" }, [
                  _c("table", { staticClass: "variableTable" }, [
                    _c("tr", [
                      _c("th", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("Variável"),
                      ]),
                      _c("th", [_vm._v("Valor padrão")]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.variables, function (item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [_vm._v(_vm._s(item.value))]),
                          _c(
                            "td",
                            [
                              _c("z-input", {
                                attrs: { hideDetails: true },
                                model: {
                                  value: _vm.defaultValues[item.value],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.defaultValues, item.value, $$v)
                                  },
                                  expression: "defaultValues[item.value]",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "modal--actions mt-3" },
                  [
                    _c("z-btn", {
                      attrs: { text: "Ok", primary: "", rounded: "" },
                      on: {
                        click: function ($event) {
                          _vm.openVariablesModal = false
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "800" },
          model: {
            value: _vm.openUtmModal,
            callback: function ($$v) {
              _vm.openUtmModal = $$v
            },
            expression: "openUtmModal",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal",
              staticStyle: { "z-index": "9999 !important" },
            },
            [
              _c("div", { staticClass: "modal__header" }, [
                _c("div", { staticClass: "modal__header__title" }, [
                  _c("span", [_vm._v("Utm Tags")]),
                  _c("br"),
                ]),
                _c(
                  "div",
                  { staticClass: "action" },
                  [
                    _c(
                      "v-icon",
                      {
                        on: {
                          click: function ($event) {
                            _vm.openUtmModal = false
                          },
                        },
                      },
                      [_vm._v("mdi-close")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "modal__body" }, [
                _c("div", { staticClass: "variableTableDiv" }, [
                  _c("table", { staticClass: "variableTable" }, [
                    _c("tr", [
                      _c("th", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("Tag"),
                      ]),
                      _c("th", [_vm._v("Valor")]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.utmNames, function (item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [_vm._v(_vm._s(item))]),
                          _c(
                            "td",
                            [
                              _c("z-input", {
                                attrs: { hideDetails: true },
                                model: {
                                  value: _vm.templateToSave[item],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.templateToSave, item, $$v)
                                  },
                                  expression: "templateToSave[item]",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "modal--actions mt-3" },
                  [
                    _c("z-btn", {
                      attrs: { text: "Ok", primary: "", rounded: "" },
                      on: {
                        click: function ($event) {
                          _vm.openVariablesModal = false
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "left" }, [
      _c("span", [_vm._v(" CONFIGURE O TEMPLATE SELECIONADO ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "sensor-2" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }